.our-services {
  display: flex;
  flex-wrap: wrap;
  min-height: 700px;
  box-sizing: border-box;
  background-color: #F9FAFC;

  .col {
    position: relative;
    flex-basis: 50%;
    height: 100%;
    max-width: 100%;
  }

  img {
    width: 100%;
    height: 700px;
    object-fit: cover;

    @media (max-width: 560px) {
      height: 400px;
    }
  }

  h2 {
    display: block;
    text-align: left;
    font-family: 'Cormorant Garamond';
    font-weight: 500;
    font-size: 45px;
    line-height: 70px;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    margin: 138px 20px 26px 20.6%;
  }

  
  #thumbnail-carousel .splide__list li {
    width: 100%;
    text-align: left;
    font-family: 'Cormorant Garamond';
    font-weight: 500;
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: 0px;
    color: #a2a2a2;
    border: none !important;
    transition: color 0.3s ease;
    min-height: 36px;

    &::before {
      display: inline-block;
      content: '';
      width: calc(20.6% - 17.71px);
      height: 0;
      border-top: 3px solid transparent;
      margin-right: 17.71px;
      transition: all 0.3s ease;
      vertical-align: middle;
    }

    &.is-active {
      color: #000;

      &::before {
        border-top: 3px solid #7990A9;
      }
    }
  }

  .btn {
    margin-top: 44px;
    margin-left: 20.6%;

    &.only-mobile {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    #thumbnail-carousel .splide__list li {
      font-size: 24px;
    }
  }

  @media (max-width: 720px) {
    flex-direction: column-reverse;
    background: rgb(249,250,252);
    background: linear-gradient(90deg, rgba(249,250,252,1) 0%, rgba(249,250,252,1) 100%);

    h2 {
      margin: 79px auto 20px;
      text-align: center;
    }

    .col {
      text-align: center;
    }

    #thumbnail-carousel {
      padding-bottom: 40px;
      margin-bottom: 47px;
      
      .splide__list li {
        text-align: center;
        
        &::before {
          content: unset;
        }
      }

      .splide__pagination {
        &__page {
          background-color: #C3C3C3;

          &.is-active {
            background-color: #7990A9;
            transform: unset;
          }
        }
      }
    }
    
    .btn {
      display: none;

      &.only-mobile {
        display: inline-block;
        margin: 60px auto 88px;
        height: 52px;
        min-width: 318px;
        font-size: 16px;
        line-height: 48px;
      }
    }
  }
}